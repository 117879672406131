export default {
  index: {
    system_name: '',
    system_name1: '主数据管理系统',
    system_name2: '经销商信息系统',
    hello: '您好',
    your_role: '您的角色是',
    switch_language: '已成功切换为中文简体',
    element_ui_size_large: '大',
    element_ui_size_small: '小',
    switch_size: '已成功切换字体大小',
    shop_audit: {
      view_type1: '待处理',
      view_type2: '已处理',
      view_type3: '我发起的',
      more: '查看更多',
      add_shop: '新增门店',
      update_shop: '修改门店'
    }
  },
  route: {
    dashboard: '首页',
    dashboard2: '新首页(开发中)',
    uploadmonitor: '上传监控',
    master_data: '主数据管理',
    system_setting: '系统设置',
    distributordata: '经销商数据',
    organization: '1.组织管理',
    organization_list: '列表模式',
    organization_tree: '树形模式',
    organization_add: '新增组织',
    organization_edit: '编辑组织',
    organization_delete: '删除组织',
    gm_organization: '1. 组织',
    gm_organization_list: '1.1 列表模式',
    gm_organization_tree: '1.2 树形模式',
    gm_organization_add: '新增组织',
    gm_organization_edit: '编辑组织',
    gm_organization_delete: '删除组织',
    city: '行政区划',
    city_list: '列表模式',
    city_tree: '树形模式',
    city_add: '新增行政区划',
    city_edit: '编辑行政区划',
    city_delete: '删除行政区划',
    gm_city: '2. 地理',
    gm_city_list: '2.1 列表模式',
    gm_city_tree: '2.2 树形模式',
    gm_city_add: '新增地理',
    gm_city_edit: '编辑地理',
    gm_city_delete: '删除地理',
    user: '人员管理',
    user_list: '5.人员管理',
    user_add: '新增人员',
    user_edit: '编辑人员',
    user_delete: '删除人员',
    gm_user: '0. 人员管理',
    gm_user_list: '0.3 人员明细设置',
    gm_user_add: '新增人员',
    gm_user_edit: '编辑人员',
    gm_user_delete: '删除人员',
    position: '职位管理',
    position_list: '4.职位管理',
    position_add: '新增职位',
    position_edit: '编辑职位',
    position_delete: '删除职位',
    gm_position: '职位管理',
    gm_position_list: '0.2 职位名称设置',
    gm_position_add: '新增职位',
    gm_position_edit: '编辑职位',
    gm_position_delete: '删除职位',
    role: '权限角色管理',
    role_list: '2.权限角色管理',
    role_add: '新增权限角色',
    role_edit: '编辑权限角色',
    role_delete: '删除权限角色',
    gm_role: '权限角色管理',
    gm_role_list: '0.1 权限设置',
    gm_role_add: '新增权限角色',
    gm_role_edit: '编辑权限角色',
    gm_role_delete: '删除权限角色',
    roleworkflow: '流程角色管理',
    roleworkflow_list: '3. 流程角色管理',
    roleworkflow_add: '新增流程角色',
    roleworkflow_edit: '编辑流程角色',
    roleworkflow_delete: '删除流程角色',
    resource: '权限资源管理',
    resource_list: '列表模式',
    resource_tree: '树形模式',
    resource_add: '新增权限资源',
    resource_edit: '编辑权限资源',
    resource_delete: '删除权限资源',
    distributor: '经销商管理',
    distributor_list: '6.经销商管理',
    distributor_add: '新增经销商',
    distributor_edit: '编辑经销商',
    distributor_delete: '删除经销商',
    distributorchange: '经销商变更',
    distributorchange_list: '经销商变更',
    distributorchange_add: '新增经销商变更',
    distributorchange_edit: '编辑经销商变更',
    distributorchange_delete: '删除经销商变更',
    gm_distributor: '经销商管理',
    gm_distributor_list: '4.2 经销商管理',
    gm_distributor_add: '新增经销商',
    gm_distributor_edit: '编辑经销商',
    gm_distributor_delete: '删除经销商',
    gm_distributorchange: '经销商变更',
    gm_distributorchange_list: '经销商变更',
    gm_distributorchange_add: '新增经销商变更',
    gm_distributorchange_edit: '编辑经销商变更',
    gm_distributorchange_delete: '删除经销商变更',
    product: '产品管理',
    product_list: '产品管理',
    product_add: '新增产品',
    product_edit: '编辑产品',
    product_delete: '删除产品',
    productprice: '产品价格管理',
    productprice_list: '产品价格管理',
    productprice_add: '新增产品价格',
    productprice_edit: '编辑产品价格',
    productprice_delete: '删除产品价格',
    productunitexchange: '产品单位换算',
    productunitexchange_list: '产品单位换算管理',
    productunitexchange_add: '新增产品单位换算',
    productunitexchange_edit: '编辑产品单位换算',
    productunitexchange_delete: '删除产品单位换算',
    productunitexchange_units: '维护产品单位',
    productunitcheck: '产品单位换算检查',
    gm_product: '3.产品',
    gm_product_list: '3.4 产品管理',
    gm_product_add: '新增产品',
    gm_product_edit: '编辑产品',
    gm_product_delete: '删除产品',
    gm_productprice: '产品价格管理',
    gm_productprice_list: '3.2 产品价格',
    gm_productprice_add: '新增产品价格',
    gm_productprice_edit: '编辑产品价格',
    gm_productprice_delete: '删除产品价格',
    gm_productunitexchange: '产品单位换算',
    gm_productunitexchange_list: '3.3 产品单位换算',
    gm_productunitexchange_add: '新增产品单位换算',
    gm_productunitexchange_edit: '编辑产品单位换算',
    gm_productunitexchange_delete: '删除产品单位换算',
    gm_productunitexchange_units: '维护产品单位',
    gm_productunitcheck: '产品单位换算检查',
    shop: '终端门店管理',
    shop_list: '终端门店管理',
    shop_add: '新增终端门店',
    shop_edit: '编辑终端门店',
    shop_delete: '删除终端门店',
    shop_audit: '门店审批',
    gm_shop: '5. 门店',
    gm_shop_list: '5.1 门店主数据',
    gm_shop_add: '新增终端门店',
    gm_shop_edit: '编辑终端门店',
    gm_shop_delete: '删除终端门店',
    gm_shop_audit: '门店审批',
    client: '0.客户配置',
    client_list: '0.客户配置',
    client_add: '新增客户',
    client_edit: '编辑客户',
    client_delete: '删除客户',
    client_app_config_data_center: '客户数据中心',
    t_client_project_config_code: '客户配置',
    t_client_project_config_code_list: '客户配置',
    t_client_project_config_code_add: '新增客户配置',
    t_client_project_config_code_edit: '编辑客户配置',
    t_client_project_config_code_delete: '删除客户配置',
    distributor_app_code: '7.客户端配置',
    distributor_app_code_list: '7.客户端配置',
    distributor_app_code_add: '新增客户端配置',
    distributor_app_code_edit: '编辑客户端配置',
    distributor_app_code_delete: '删除客户端配置',
    app_config_datacenter_code: '服务配置',
    app_config_datacenter_code_list: '服务配置',
    app_config_datacenter_code_add: '新增服务配置',
    app_config_datacenter_code_edit: '编辑服务配置',
    app_config_datacenter_code_delete: '删除服务配置',
    t_app_conf_server_datacenter_domain_code: '服务域名配置',
    t_app_conf_server_datacenter_domain_code_list: '服务域名配置',
    t_app_conf_server_datacenter_domain_code_add: '新增服务域名配置',
    t_app_conf_server_datacenter_domain_code_edit: '编辑服务域名配置',
    t_app_conf_server_datacenter_domain_code_delete: '删除服务IP配置',
    t_app_conf_server_datacenter_ip_code: '服务IP配置',
    t_app_conf_server_datacenter_ip_code_list: '服务IP配置',
    t_app_conf_server_datacenter_ip_code_add: '新增服务IP配置',
    t_app_conf_server_datacenter_ip_code_edit: '编辑服务IP配置',
    t_app_conf_server_datacenter_ip_code_delete: '删除服务IP配置',
    t_app_conf_server_url_code: '服务Url配置',
    t_app_conf_server_url_code_list: '服务Url配置',
    t_app_conf_server_url_code_add: '新增服务Url配置',
    t_app_conf_server_url_code_edit: '编辑服务Url配置',
    t_app_conf_server_url_code_delete: '删除服务Url配置',
    t_app_conf_application_property_code: '应用Property配置',
    t_app_conf_application_property_code_list: '应用Property配置',
    t_app_conf_application_property_code_add: '新增应用Property配置',
    t_app_conf_application_property_code_edit: '编辑应用Property配置',
    t_app_conf_application_property_code_delete: '删除应用Property配置',
    t_app_conf_application_code: '应用Xml配置',
    t_app_conf_application_code_list: '应用Xml配置',
    t_app_conf_application_code_add: '新增应用Xml配置',
    t_app_conf_application_code_edit: '编辑应用Xml配置',
    t_app_conf_application_code_delete: '删除应用Xml配置',
    t_app_conf_job_code: '任务配置',
    t_app_conf_job_code_list: '任务配置',
    t_app_conf_job_code_add: '新增任务配置',
    t_app_conf_job_code_edit: '编辑任务配置',
    t_app_conf_job_code_delete: '删除任务配置',
    t_app_conf_db_extract_conn_parameter_code: '数据库参数配置',
    t_app_conf_db_extract_conn_parameter_code_list: '数据库参数配置',
    t_app_conf_db_extract_conn_parameter_code_add: '新增数据库参数配置',
    t_app_conf_db_extract_conn_parameter_code_edit: '编辑数据库参数配置',
    t_app_conf_db_extract_conn_parameter_code_delete: '删除数据库参数配置',
    t_app_conf_db_extract_file_code: '数据库抽取配置',
    t_app_conf_db_extract_file_code_list: '数据库抽取配置',
    t_app_conf_db_extract_file_code_add: '新增数据库抽取配置',
    t_app_conf_db_extract_file_code_edit: '编辑数据库抽取配置',
    t_app_conf_db_extract_file_code_delete: '删除数据库抽取配置',
    t_app_conf_db_extract_file_parameter_value_code: '数据库抽取参数配置',
    t_app_conf_db_extract_file_parameter_value_code_list: '数据库抽取参数配置',
    t_app_conf_db_extract_file_parameter_value_code_add: '新增数据库抽取参数配置',
    t_app_conf_db_extract_file_parameter_value_code_edit: '编辑数据库抽取参数配置',
    t_app_conf_db_extract_file_parameter_value_code_delete: '删除数据库抽取参数配置',
    t_export_file_code: '导出管理',
    t_export_file_code_list: '文件列表',
    t_export_file_code_add: '新增导出文件',
    t_export_file_code_edit: '编辑导出文件',
    t_export_file_code_delete: '删除导出文件',
    t_report_subscribe_code: '报表订阅',
    t_report_subscribe_code_list: '订阅列表',
    t_report_subscribe_code_add: '新增报表订阅',
    t_report_subscribe_code_edit: '编辑报表订阅',
    t_report_subscribe_code_delete: '删除报表订阅',
    t_report_subscribe_run_code: '运行日志',
    t_prop_option_code: '客户下拉字典管理',
    t_prop_option_code_list: '客户下拉字典管理',
    install_check_compare: 'ERP数据比对',
    install_check_compare_purchase: '进货数据',
    install_check_compare_sales: '销售数据',
    install_check_compare_stock: '库存数据',
    distributorshop: '经销商门店',
    distributorshop_list: '经销商门店',
    shopmatch: '门店匹配',
    shopmatch_overview: '门店智能匹配总览',
    shopmatch_shoplist: '门店匹配确认',
    shopmatch_client: '门店匹配',
    shopmatch_client_overview: '门店匹配确认总览',
    shopmatch_client_shoplist: '门店匹配确认',
    distributorproduct: '经销商产品',
    distributorproduct_list: '经销商产品',
    productmatch: '产品匹配',
    productmatch_overview: '产品智能匹配总览',
    productmatch_productlist: '产品匹配确认',
    productmatch_client: '产品匹配',
    productmatch_client_overview: '产品匹配确认总览',
    productmatch_client_productlist: '产品匹配确认',
    distributorclientbilltype: '单据类型和状态匹配',
    distributorclientbilltype_list: '单据类型和状态匹配',
    distributorclientbilltype_add: '新增匹配',
    distributorclientbilltype_edit: '编辑匹配',
    distributorclientbilltype_delete: '删除匹配',
    data_template: '数据模板',
    data_template_list: '下载模板管理',
    data_template_detail: '模板详情',
    import_data: '数据导入',
    dms_rawdata: '原始数据查看',
    dms_rawdatasales: '原始销售数据',
    dms_rawdatasales_list: '原始销售数据',
    dms_rawdatasales_add: '新增原始销售数据',
    dms_rawdatasales_edit: '编辑原始销售数据',
    dms_rawdatasales_delete: '删除原始销售数据',
    dms_rawdatatransfer: '原始调拨数据',
    dms_rawdatatransfer_list: '原始调拨数据',
    dms_rawdatatransfer_add: '新增原始调拨数据',
    dms_rawdatatransfer_edit: '编辑原始调拨数据',
    dms_rawdatatransfer_delete: '删除原始调拨数据',
    dms_rawdatapurchase: '原始进货数据',
    dms_rawdatapurchase_list: '原始进货数据',
    dms_rawdatapurchase_add: '新增原始进货数据',
    dms_rawdatapurchase_edit: '编辑原始进货数据',
    dms_rawdatapurchase_delete: '删除原始进货数据',
    dms_rawdatastock: '原始库存数据',
    dms_rawdatastock_list: '原始库存数据',
    dms_rawdatastock_add: '新增原始库存数据',
    dms_rawdatastock_edit: '编辑原始库存数据',
    dms_rawdatastock_delete: '删除原始库存数据',
    dms_rawdatachange: '原始其他出入库数据',
    dms_rawdatachange_list: '原始其他出入库数据',
    dms_rawdatachange_add: '新增原始其他出入库数据',
    dms_rawdatachange_edit: '编辑原始其他出入库数据',
    dms_rawdatachange_delete: '删除原始其他出入库数据',
    dms_matcheddata: '匹配后数据查看',
    dms_matcheddatasales: '匹配后销售数据',
    dms_matcheddatasales_list: '匹配后销售数据',
    dms_matcheddatasales_add: '新增匹配后销售数据',
    dms_matcheddatasales_edit: '编辑匹配后销售数据',
    dms_matcheddatasales_delete: '删除匹配后销售数据',
    dms_matcheddatatransfer: '匹配后调拨数据',
    dms_matcheddatatransfer_list: '匹配后调拨数据',
    dms_matcheddatatransfer_add: '新增匹配后调拨数据',
    dms_matcheddatatransfer_edit: '编辑匹配后调拨数据',
    dms_matcheddatatransfer_delete: '删除匹配后调拨数据',
    dms_matcheddatapurchase: '匹配后进货数据',
    dms_matcheddatapurchase_list: '匹配后进货数据',
    dms_matcheddatapurchase_add: '新增匹配后进货数据',
    dms_matcheddatapurchase_edit: '编辑匹配后进货数据',
    dms_matcheddatapurchase_delete: '删除匹配后进货数据',
    dms_matcheddatastock: '匹配后库存数据',
    dms_matcheddatastock_list: '匹配后库存数据',
    dms_matcheddatastock_add: '新增匹配后库存数据',
    dms_matcheddatastock_edit: '编辑匹配后库存数据',
    dms_matcheddatastock_delete: '删除匹配后库存数据',
    dms_matcheddatachange: '匹配后其他出入库数据',
    dms_matcheddatachange_list: '匹配后其他出入库数据',
    dms_matcheddatachange_add: '新增匹配后其他出入库数据',
    dms_matcheddatachange_edit: '编辑匹配后其他出入库数据',
    dms_matcheddatachange_delete: '删除匹配后其他出入库数据',
    dms_converteddata: '转换后数据查看',
    dms_converteddatasales: '转换后销售数据',
    dms_converteddatasales_list: '转换后销售数据',
    dms_converteddatasales_add: '新增转换后销售数据',
    dms_converteddatasales_edit: '编辑转换后销售数据',
    dms_converteddatasales_delete: '删除转换后销售数据',
    dms_converteddatatransfer: '转换后调拨数据',
    dms_converteddatatransfer_list: '转换后调拨数据',
    dms_converteddatatransfer_add: '新增转换后调拨数据',
    dms_converteddatatransfer_edit: '编辑转换后调拨数据',
    dms_converteddatatransfer_delete: '删除转换后调拨数据',
    dms_converteddatapurchase: '转换后进货数据',
    dms_converteddatapurchase_list: '转换后进货数据',
    dms_converteddatapurchase_add: '新增转换后进货数据',
    dms_converteddatapurchase_edit: '编辑转换后进货数据',
    dms_converteddatapurchase_delete: '删除转换后进货数据',
    dms_converteddatastock: '转换后库存数据',
    dms_converteddatastock_list: '转换后库存数据',
    dms_converteddatastock_add: '新增转换后库存数据',
    dms_converteddatastock_edit: '编辑转换后库存数据',
    dms_converteddatastock_delete: '删除转换后库存数据',
    dms_converteddatachange: '转换后其他出入库数据',
    dms_converteddatachange_list: '转换后其他出入库数据',
    dms_converteddatachange_add: '新增转换后其他出入库数据',
    dms_converteddatachange_edit: '编辑转换后其他出入库数据',
    dms_converteddatachange_delete: '删除转换后其他出入库数据',
    dms_settledata: '结存数据查看',
    dms_settledatasales: '结存销售数据',
    dms_settledatasales_list: '结存销售数据',
    dms_settledatasales_add: '新增结存销售数据',
    dms_settledatasales_edit: '编辑结存销售数据',
    dms_settledatasales_delete: '删除结存销售数据',
    dms_settledatatransfer: '结存调拨数据',
    dms_settledatatransfer_list: '结存调拨数据',
    dms_settledatatransfer_add: '新增结存调拨数据',
    dms_settledatatransfer_edit: '编辑结存调拨数据',
    dms_settledatatransfer_delete: '删除结存调拨数据',
    dms_settledatapurchase: '结存进货数据',
    dms_settledatapurchase_list: '结存进货数据',
    dms_settledatapurchase_add: '新增结存进货数据',
    dms_settledatapurchase_edit: '编辑结存进货数据',
    dms_settledatapurchase_delete: '删除结存进货数据',
    dms_settledatastock: '结存库存数据',
    dms_settledatastock_list: '结存库存数据',
    dms_settledatastock_add: '新增结存库存数据',
    dms_settledatastock_edit: '编辑结存库存数据',
    dms_settledatastock_delete: '删除结存库存数据',
    dms_settledatachange: '结存其他出入库数据',
    dms_settledatachange_list: '结存其他出入库数据',
    dms_settledatachange_add: '新增结存其他出入库数据',
    dms_settledatachange_edit: '编辑结存其他出入库数据',
    dms_settledatachange_delete: '删除结存其他出入库数据',
    pos_rawdata: '原始数据查看',
    pos_rawdatasales: '原始销售数据',
    pos_rawdatatransfer: '原始调拨数据',
    pos_rawdatapurchase: '原始进货数据',
    pos_rawdatastock: '原始库存数据',
    pos_rawdatachange: '原始其他出入库数据',
    pos_matcheddata: '匹配后数据查看',
    pos_matcheddatasales: '匹配后销售数据',
    pos_matcheddatatransfer: '匹配后调拨数据',
    pos_matcheddatapurchase: '匹配后进货数据',
    pos_matcheddatastock: '匹配后库存数据',
    pos_matcheddatachange: '匹配后其他出入库数据',
    pos_converteddata: '转换后数据查看',
    pos_converteddatasales: '转换后销售数据',
    pos_converteddatatransfer: '转换后调拨数据',
    pos_converteddatapurchase: '转换后进货数据',
    pos_converteddatastock: '转换后库存数据',
    pos_converteddatachange: '转换后其他出入库数据',
    pos_settledata: '结存数据查看',
    pos_settledatasales: '结存销售数据',
    pos_settledatatransfer: '结存调拨数据',
    pos_settledatapurchase: '结存进货数据',
    pos_settledatastock: '结存库存数据',
    pos_settledatachange: '结存其他出入库数据',
    distributorshopmatch: '经销商门店匹配维护',
    distributorshopmatch_list: '门店匹配维护',
    distributorshopmatch_edit: '编辑经销商门店匹配',
    distributorproductmatch: '经销商产品匹配维护',
    distributorproductmatch_list: '产品匹配维护',
    distributorproductmatch_edit: '编辑经销商产品匹配',
    duplicate_shop_check: '重复门店检查',
    distributorbalance: '进销存平衡检查',
    distributorbalance_check: '进销存平衡检查',
    distributorbalance_detail: '进销存平衡详细数据',
    productmatchcheck: '产品匹配检查',
    productmatchcheck1: '产品匹配检查1（经销商产品编码名称相同，匹配后产品标准编码不同）',
    productmatchcheck2: '产品匹配检查2（经销商产品编码名称相同，匹配后产品标准单位不同）',
    productmatchcheck3: '产品匹配检查3（经销商产品编码相同名称不相同，匹配后产品标准编码不同）',
    productmatchcheck4: '产品匹配检查4（经销商产品编码相同名称不相同单位相同，匹配后产品标准单位不同）',
    productmatchmarkuprate: '产品匹配加价率检查',
    client_period: '月结周期维护',
    client_period_list: '月结周期维护',
    client_period_add: '新增月结周期',
    client_period_edit: '编辑月结周期',
    client_portal_upload_schedule: 'Portal上传时间维护',
    client_portal_upload_schedule_list: 'Portal上传时间维护',
    client_portal_upload_schedule_add: '新增上传时间',
    client_portal_upload_schedule_edit: '编辑上传时间',
    app_manager: '客户端管理',
    data_view_dms: 'DMS数据查看',
    data_view_pos: 'POS数据查看',
    data_match: '数据匹配',
    data_check: '数据检查',
    data_dictionary_manage: '字典管理',
    data_dictionary_option: '数据字典',
    data_dictionary_prop: '数据表',
    api_log: '日志管理',
    gm_sapshop: 'SAP门店管理',
    gm_sapshop_list: '4.1 SAP客户主数据接口',
    gm_sapshop_add: '新增SAP门店',
    gm_sapshop_edit: '编辑SAP门店',
    gm_sap_mdm_shop_match: '5.2 SAP与MDM匹配',
    gm_sapproduct: 'SAP产品管理',
    gm_sapproduct_list: '3.5 SAP产品接口',
    gm_sapproduct_add: '新增SAP产品',
    gm_sapproduct_edit: '编辑SAP产品',
    gm_mercury_product: '3.1 产品主数据',
    gm_sap_file_load: 'SAP文件处理日志',
    gm_import_data: '数据导入',
    todo4: '*公告管理',
    todo5: '*数据交互',
    todo6: '*采集字段维护',
    todo14: '*客户端公告',
    report: '报表管理',
    report_distributor_sales_product_top20: '经销商销售Top20产品',
    report_distributor_sales_shop_top20: '经销商销售Top20终端',
    report_distributor_pss: '经销商进销存报表',
    report_distributor_stock: '经销商库存报表',
    report_distributor_sales: '经销商销售报表',
    report_distributor_sales_rank: '经销商销售额排名报表',
    report_product_distribution: '产品分销报表',
    report_product_sales_increase_rate: '产品销售增长率Top10报表',
    todo27: '*一级经销商出货报表',
    todo28: '*二级经销商出货报表',
    report_shop_number: 'DMS终端数量报表',
    report_shop_new: 'DMS新增终端报表',
    report_shop_lost: 'DMS流失终端报表',
    report_mdm_shop_number: '主数据终端数量报表',
    demo: 'Demo',
    demo_tianyancha: '天眼查',
    demo_map_gm: '门店地图',
    gm_client: '4. 客户',
    gm_client_master: '4.1 客户主数据'
  },
  navbar: {
    dashboard: '首页',
    logOut: '退出登录',
    modifyPass: '修改密码',
    oldPass: '旧密码',
    newPass: '新密码',
    newPass2: '再次输入新密码',
    diffPass: '两次输入密码不一致',
    size: '布局大小'
  },
  login: {
    title: '',
    resetPass: '重置密码',
    logIn: '登录',
    username: '账号',
    email: '邮箱',
    password: '密码',
    send_reset_email_success: '提交成功，请查收邮件',
    reset_pass_success: '密码重置成功',
    token_timeout: 'Token已失效，请重新登录'
  },
  label: {
    pleaseSelect: '请选择',
    inputKeywordToFilter: '输入关键字搜索',
    title404: '抱歉，您访问的页面不存在。',
    message404: '可能是因为输入的链接地址有误，或者您没有权限访问该页面。请点击下面按钮返回首页。',
    backToHome: '返回首页',
    recordChangeLog: '记录更新日志',
    similarShop: '相似门店',
    audit: '审批',
    auditRecordChange: '记录更新',
    auditSteps: '审批步骤',
    auditComment: '审批意见',
    auditCommentNotEmpty: '审批意见不能为空',
    auditNewShop: '门店新增申请',
    auditEditShop: '门店更新申请',
    newShop: '新店',
    view: '查看',
    checkSimilarShop: '疑似重复'
  },
  button: {
    refresh: '刷新',
    search: '搜索',
    add: '新增',
    export: '导出',
    import: '导入',
    edit: '编辑',
    delete: '删除',
    cancel: '取消',
    reset: '重置',
    confirm: '确定',
    close: '关闭',
    back: '返回',
    save: '保存',
    clear: '清空',
    match: '匹配',
    matchConfirm: '确认',
    batchConfirm: '批量确认',
    calculateData: '计算数据量',
    operation: '操作',
    runSettle: '执行结存（日结+月结）',
    runMatch: '执行智能匹配',
    runDuplicateShopCheck: '执行重复门店检查',
    expand: '展开',
    collapse: '折叠',
    updateHistoricalData: '更新历史数据',
    reportEditor: '报表编辑器',
    generateChart: '生成图形',
    exportImage: '导出图片',
    pass: '通过',
    reject: '不通过',
    confirmAddShop: '确认添加新店',
    confirmDuplicated: '通过',
    confirmNotDuplicated: '不通过',
    reApply: '再次发起',
    uploadData: '上传数据',
    confirmUpload: '确认上传',
    openUploadWindow: '打开上传窗口',
    zeroDeclaration: '零申报',
    refreshDD: '刷新系统缓存',
    loadSapFile: '从FTP加载SAP文件'
  },
  loading: {
    default: '玩命加载中...',
    login: '登录中...'
  },
  messageBox: {
    alert: {
      confirmButton: '确定'
    },
    confirm: {
      confirmButton: '确定',
      cancelButton: '取消',
      title: '确定',
      body: '您确定执行此操作吗？',
      addNewWithoutSave: '记录尚未保存，本操作将会清除现有输入框，您确定要继续吗？'
    }
  },
  notification: {
    title: '提醒',
    success: {
      title: '成功',
      body: '操作成功',
      body_13: '提交门店审批成功'
    },
    error: {
      title: '错误',
      body: '操作失败'
    },
    loginFailed: {
      title: '错误',
      body: '登录失败: 错误的用户名或密码'
    }
  },
  table: {
    title: '标题',
    id: 'ID',
    actions: '操作',
    batchEdit: {
      beforeSelectedNumText: '',
      afterSelectedNumText: ' 条记录被选中 ',
      openDialogButton: '批量更新',
      noSelectedData: '没有记录被选中，请勾选您想更新的记录 ',
      dialogTitle: '批量更新',
      dialogTipsBeforeNum: '将以下更新应用于上面的',
      dialogTipsAfterNum: '条记录: ',
      dialogTipsEqual: ' 等于 ',
      dialogNoInputWarn: '请输入',
      dialogChooseUpdateColumn: '选择...',
      dialogConfirmButton: '确定',
      dialogCancelButton: '取消',
      dialogSecondConfirm: '您确定执行此操作吗？',
      dialogMessageTitle: '确定',
      successMessageTitle: '成功',
      successMessageBody: '操作成功'
    }
  },
  shopmatch: {
    noMoreShopMatched: '没有更多匹配相似门店。',
    searchText: '试试输入关键字搜索更多门店 (如有多个关键字，用空格分隔)：',
    searchPlaceholder: '门店编号/店名/地址',
    searchShopMatchConfirm: '您确定匹配这家门店吗?',
    searchResultTipsBeforeNum: '最多显示前',
    searchResultTipsAfterNum: '条记录',
    searchResultBeforeNum: '搜索结果: ',
    searchResultAfterNum: '条记录',
    noSelectedData: '没有记录被选中，请勾选您想更新的记录',
    noSelectedShop: '请选择匹配的门店'
  },
  productmatch: {
    noMoreProductMatched: '没有更多匹配相似产品。',
    searchText: '试试输入关键字搜索更多产品 (如有多个关键字，用空格分隔)：',
    searchPlaceholder: '产品编号/名称/规格',
    searchProductMatchConfirm: '您确定匹配这个产品吗?',
    searchResultTipsBeforeNum: '最多显示前',
    searchResultTipsAfterNum: '条记录',
    searchResultBeforeNum: '搜索结果: ',
    searchResultAfterNum: '条记录',
    noSelectedData: '没有记录被选中，请勾选您想更新的记录',
    noSelectedProduct: '请选择匹配的产品',
    noSelectedProductUnit: '请选择匹配的产品单位',
    noProductUnitExchange: '无单位换算'
  },
  distributorshopmatch: {
    reverse: {
      title: '计算冲销数据量',
      distributorShop: '经销商门店',
      originalShop: '原匹配主数据门店',
      newShop: '变更匹配主数据门店',
      startDate: '冲销开始日期',
      endDate: '冲销结束日期',
      all: '全部',
      uptonow: '至今'
    },
    billType: {
      label: '单据类型',
      amount: '数量',
      minDate: '最小日期',
      maxDate: '最大日期',
      sales: '销售单'
    }
  },
  distributorproductmatch: {
    reverse: {
      title: '计算冲销数据量',
      distributorProduct: '经销商产品',
      originalProduct: '原匹配主数据产品',
      newProduct: '变更匹配主数据产品',
      startDate: '冲销开始日期',
      endDate: '冲销结束日期',
      all: '全部',
      uptonow: '至今'
    },
    billType: {
      label: '单据类型',
      amount: '数量',
      minDate: '最小日期',
      maxDate: '最大日期',
      purchase: '进货单',
      sales: '销售单',
      stock: '库存',
      stockIn: '其他入库单',
      stockOut: '其他出库单'
    }
  },
  report: {
    mode: {
      table: '表格',
      chart: '图形',
      chartType11: '普通折线图',
      chartType12: '堆叠折线图',
      chartType13: '面积图',
      chartType21: '普通柱状图',
      chartType22: '堆叠柱状图',
      chartType23: '横向柱状图',
      chartType31: '普通饼图'
    },
    selectChartType: '选择图形类别',
    selectXAxisFields: '选择X轴维度',
    selectYAxisFields: '选择Y轴维度',
    selectSeriesFields: '选择指标',
    percentage: '比例'
  },
  reportEditor: {
    dialogTitle: '报表编辑器',
    chooseGroupby: '请选择分组字段',
    chooseSelect: '请选择显示字段',
    unchecked: '未选择',
    checked: '已选择',
    dialogMessageNoChecked: '已选择字段不能为空，请选择',
    dialogConfirmButton: '确定',
    dialogCancelButton: '取消',
    dialogMessageTitle: '确定',
    successMessageTitle: '成功',
    successMessageBody: '操作成功'
  },
  rules: {
    login: {
      username: {
        required: '请输入用户名'
      },
      password: {
        required: '请输入密码'
      }
    },
    common: {
      required: '必填项不能为空',
      maxlength10: '该项最大长度是10',
      maxlength20: '该项最大长度是20',
      maxlength50: '该项最大长度是50',
      maxlength100: '该项最大长度是100',
      maxlength200: '该项最大长度是200',
      maxlength500: '该项最大长度是500',
      cannotEmpty: '必填项不能为空',
      largerThan0: '数字必须大于0',
      cannotDuplicated: '该项不能重复',
      mustbe: '必须是',
      uploadExceed_1: '{0}最多能上传{1}张',
      uploadExceed_2: '{0}最多能上传{1}张'
    }
  },
  common: {
    info: '提示',
    delete_confirm: '是否要删除?',
    yes: '是',
    no: '否',
    download: '下载',
    fileName: '文件名',
    fileSize: '文件大小',
    download_confirm: '确定下载？'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  dashboard: {
    panel_group: {
      uploaded_distributors: '已上传经销商数',
      purchase_amount_mtd: 'MTD进货额',
      purchase_mtd: 'MTD进货',
      current_month_target: '本月目标',
      target_completion_rate: '完成率',
      mom: '同比',
      yoy: '环比',
      stock: '库存',
      cases: '箱',
      turn_over_days: '周转天数',
      sale_amount_mtd: 'MTD出货额',
      sale_mtd: 'MTD出货'
    },
    stock_days_bar_chart: {
      stock_standard_amount: '库存标准金额',
      stock_turn_over_days: '库存周转天数'
    },
    shop_rank_table: {
      terminal_sales: '终端销售'
    },
    product_rank_table: {
      product_sales: '产品销售'
    },
    return_goods_line_chart: {
      product_return_trend: '产品退货趋势'
    },
    uploadmonitor: {
      item1: '总客户端',
      item2: '今日已上传',
      item3: '今日待上传',
      item4: '3日未上传'
    }
  },
  importFile: {
    importFile: '上传文件',
    downloadTemplate: '下载数据模板',
    pleaseSelect: '请选择...',
    importData: '数据导入',
    confirm: {
      title: '提示',
      confirmButton: '知道了',
      confirmButtonSee: '查看',
      cancelButton: '去[导出管理]看看'
    },
    importSuccess: '操作成功',
    importTimeout: '此次数据导入已超过{0}秒,系统正在异步处理中.稍后请刷新该页面查看导入结果或者前往：系统设置-导出管理 查看',
    importFail: '导入文件有错误'
  },
  exportFile: {
    confirm: {
      title: '提示',
      confirmButton: '知道了',
      confirmButtonSee: '查看',
      cancelButton: '去[导出管理]看看'
    },
    exportSuccess: '操作成功',
    exportTimeout: '此次文件导出已超过{0}秒,系统正在异步处理中.稍后您可直接在菜单：系统设置-导出管理中下载.',
    exportRowTimeout: '此次文件导出记录数已超过{0}条,系统正在异步处理中.稍后您可直接在菜单：系统设置-导出管理中下载.',
    exportFail: '导出失败'
  }
};